import React, { useState, useEffect } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl";

// Styles
// import "../sass/main.scss"
import styles from "../sass/pages/portfolio.module.scss"

const MercadoViaje = () => {

  const data = useStaticQuery(graphql`
    query ImagesMercadoViaje {
      image: file( relativePath: { eq: "redpagosMockup.png"} ) {
          id
          childImageSharp { 
            fixed {
              ...GatsbyImageSharpFixed
            }
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
      }
    }
  `);

  const title = useIntl().formatMessage({id: 'portfolio'});
  const [load] = useState(0);

  const verticalBand = React.useRef() as React.MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    verticalBand.current.style.height = document.body.offsetHeight + "px";
  }, [load]);

  return (
  <Layout color="">
    <SEO title={title} /> 
    <div ref={ verticalBand } className={ styles.verticalBand }></div>
    <article className={ "container-small " }> 
      <section className={ 'container-small '}>
        <div>
          <h3><FormattedMessage id="home_header"/></h3>
          </div>
          <p><FormattedMessage id="home_header_paragraph"/></p>

      </section>
    </article>  
  </Layout>
)}

export default MercadoViaje
